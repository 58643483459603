.App {
  text-align: center;
}

.App-header {
  background-color: #262729;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

.App-contact-section-people {
  background-color: #212326;
  font-size: calc(11px + 1vmin);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45vh;
  font-weight: 300;
}

.App-contact-section-people > div {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .App-contact-section-people {
    flex-direction: column;
  }
  .App-contact-section-people > div {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
  }
}
